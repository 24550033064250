//  Links
#links {
  margin-bottom: 1em;

  ul {
    padding-inline-start: 1em;
    list-style: none;
    display: flex;
    flex-direction: row;
    li {
      padding: 1em;
      svg {
        height: 2em;
        width: auto; } } } }
