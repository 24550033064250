// About
#about {
  padding-top: 7.5em;
  margin-bottom: 1em;

  .heading {
    font-family: "Inter Black";
    font-size: 3.5em;
    margin: 0; }

  p {
    padding-inline-start: 1em; } }

@media screen and (max-device-width: 700px) {
  // About
  #about {
    padding-top: 2em; } }
