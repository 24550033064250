// Projects
#projects {
  margin-bottom: 1em; }

.projects {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.project-showcase {
  margin: 1em;
  padding: 1.5em;
  border-radius: 0.5em;
  box-shadow: 0 0 15px 0 #6c6c6c;

  .description {
    margin-bottom: 1em; }

  h4 {
    font-family: "Inter Bold";
    font-size: 1.3em; } }

@media screen and (max-device-width: 700px) {
    // Projects
    .project-showcase {
        font-size: 85%; } }
