// Footer
#footer {
  display: flex;
  padding: 1em;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  a {
    margin: 0.2em; } }

// Options
.options {
  display: flex; }

// Theme switch
#themeSwitch {
  cursor: pointer; }

// Language switch
#languageSwitch {
  cursor: pointer;
  margin-right: 1em;

  img {
    height: 22px;
    width: 22px; } }
