// Font
@font-face {
  src: url('../fonts/Inter-Medium.ttf') format('truetype');
  font: {
    display: auto;
    family: "Inter Regular"; } }

@font-face {
  src: url('../fonts/Inter-Bold.ttf') format('truetype');
  font: {
    display: auto;
    family: "Inter Bold"; } }

@font-face {
  src: url('../fonts/Inter-Black.ttf') format('truetype');
  font: {
    display: auto;
    family: "Inter Black"; } }

// Variables
$white: #fcfbf8;
$grey: #404040;
$black: #151515;
$turquoise: #00bb90;
$green: #bfff80;
$dark-theme-black: #191d1f;
$dark-theme-white: #e0dfdd;
$dark-theme-grey: #6c6c6c;
$dark-theme-light-grey: #b3b3b3;

// Animations
@keyframes hover {
  0% {
    transform: translate(-50%, 0%) rotate(45deg); }
  50% {
    transform: translate(-50%, 2em) rotate(45deg); }
  100% {
    transform: translate(-50%, 0%) rotate(45deg); } }

// General
* {
  margin: 0;
  padding: 0;
  line-height: 1.3; }

html {
  font-family: "Inter Regular";
  background-color: $dark-theme-black;
  color: $dark-theme-white;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  text-decoration: none;
  transition: all .2s linear;
  color: $dark-theme-light-grey;

  &:hover {
    color: $green; } }

p {
  font-size: 110%; }

::selection {
  background-color: $green; }

::-webkit-scrollbar {
  width: 0.5em;
  background-color: $dark-theme-black; }

::-webkit-scrollbar-thumb {
  background-color: $dark-theme-light-grey; }

// Dark theme
// .dark
//   body
//     background-color: $dark-theme-black
//     color: $dark-theme-white

//   a
//     color: $dark-theme-light-grey

//     &:hover
//       color: $turquoise

//   .project-showcase
//     box-shadow: 0 0 15px 0 $dark-theme-grey

//   .skill-showcase
//     box-shadow: 0 0 15px 0 $dark-theme-grey

//   .btn
//     color: $dark-theme-light-grey
//     border: 0.1em solid $dark-theme-light-grey

// Button
.btn {
  border: 0.1em solid $dark-theme-light-grey;
  border-radius: 0.5em;
  padding: 0.5em;
  background: transparent;
  color: $dark-theme-light-grey;
  margin: 0.2em;

  &:hover {
    border-color: $green;
    box-shadow: 0 0 15px 0 $green;
    text-shadow: 0 0 15px 0 $green;
    color: $green;
    background: transparentize($green, 0.8); } }

// Content
#content {
  margin: 0 10em;
  font-size: 1.1em;

  h2 {
    font-size: 2em; } }

@media screen and (max-device-width: 700px) {
  #content {
    margin: 0 1.5em; } }

@media screen and (min-device-width: 700px) and (max-device-width: 1000px) {
  #content {
    margin: 0 5em; } }

// Imprint
#imprint-page {
  #imprint {
    margin-top: 5em;
    margin-left: 5em;
    margin-right: 5em;

    h1 {
      font-size: 4em;
      margin: 0.5em 0; } } }

@media screen and (max-device-width: 700px) {
  // Imprint
  #imprint-page {
    #imprint {
      margin-left: 2em;
      margin-right: 2em;

      h1 {
        font-size: 2em; } } } }

// Privacy Policy
#privacy-page {
  #privacy {
    margin-top: 5em;
    margin-left: 5em;
    margin-right: 5em;

    h1 {
      font-size: 4em;
      margin: 0.5em 0; } } }

// Responsive
@media screen and (max-device-width: 700px) {
  // Privacy Policy
  #privacy-page {
    #privacy {
      margin-left: 2em;
      margin-right: 2em;

      h1 {
        font-size: 2em; } } } }

// Error page
#error-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  h1 {
    font-size: 5em; } }
